export default {
  pathPrefix: '', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Get your Free Copy of the Ultimate Guide', // Navigation and Site Title
  siteTitleAlt: 'Get your Free Copy of the Ultimate Guide', // Alternative Site title for SEO
  siteTitleManifest: 'LifeOnScreen',
  siteUrl: 'https://digital-signage.lifeonscreen.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteHeadline: 'Get your Free Copy of the Ultimate Guide', // Headline for schema.org JSONLD
  siteBanner:
    'https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png', // Your image for og:image tag. You can find it in the /static folder
  favicon: '/media/favicon.png', // Your image for favicons. You can find it in the /src folder
  siteDescription: 'Start building your digital signage success story on public screens.', // Your site description
  siteLogo:
    'https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png', // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@LifeOnScreenHQ', // Twitter Username - Optional
  ogSiteName: 'lifeonscreendubai', // Facebook Site Name - Optional
  ogLanguage: 'en_US', // Facebook Language
}
