import React from 'react'
import styled from 'styled-components'
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share'
import {ThemeProvider, ResetCss} from 'los-design-system'
import GeneralNavigation from 'los-design-system/components/Navigation/GeneralNavigation'
import Button from 'los-design-system/components/Button'
import FacebookIcon from 'los-design-system/components/icons/FacebookIcon'
import LinkedinIcon from 'los-design-system/components/icons/LinkedinIcon'
import TwitterIcon from 'los-design-system/components/icons/TwitterIcon'
import {Title, SubtitleBold} from 'los-design-system/components/Text'
import Seo from '../components/Seo'

const GradientStyle = styled('div')`
  background-image: linear-gradient(298deg, #0adbad, #00aeef);
  min-height: calc(100vh - 68px);
  position: relative;
  padding: 64px 0 0;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 166px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
    background-size: 9px 18px;
    background-image: url(/media/dots-white.svg);
  }

  @media (min-width: 992px) {
    padding: 158px 0 0;
    min-height: calc(100vh - 90px);
  }
`

const ContentStyle = styled('div')`
  margin: 0 auto;
  max-width: 802px;
  text-align: center;
`

const A = styled('a')`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`

const ButtonsWrapperStyle = styled('div')`
  display: flex;
  justify-content: center;
`

const ImgStyle = styled('img')`
  width: 100%;
  max-width: 600px;
  height: auto;
  margin: 0 auto;
`

const PictureStyle = styled('picture')`
  display: block;
  padding: 64px 0 150px;

  @media (min-width: 992px) {
    padding: 0 67px 150px 0;
  }
`

const SocialsStyle = styled('div')`
  margin-top: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat, sans-serif;
  font-size: 17px;
  font-weight: 600;
  line-height: 1.65;
  color: #ffffff;

  span {
    margin-right: 32px;
  }
`

const LinkedinIconStyle = styled(LinkedinIcon)`
  margin-left: 24px;
`

const TwitterIconStyle = styled(TwitterIcon)`
  margin-left: 24px;
`

const ThankYou = () => {
  const title = 'Get your Free Copy of the Ultimate Guide'
  const description = 'Everything you need to know for successful DOOH campaign.'
  const url = 'https://digital-signage.lifeonscreen.com'

  return (
    <ThemeProvider theme={{}}>
      <Seo
        article={false}
        author="LifeOnScreen"
        coverImage="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png"
        title="Get your Free Copy of the Ultimate Guide"
        description="Start building your digital signage success story on public screens."
      />
      <ResetCss />
      <GeneralNavigation navItems={<div />} />
      <GradientStyle>
        <ContentStyle>
          <Title color="#fff">Your digital signage ebook is in your inbox.</Title>
          <SubtitleBold m="32px 0 75px" color="#fff">
            Thank you.
          </SubtitleBold>
          <ButtonsWrapperStyle>
            <A href="https://lifeonscreen.com" title="LifeOnScreen">
              <Button
                text="Learn more about LifeOnScreen"
                textAlign="center"
                theme="whiteBlackText"
                size="large"
              />
            </A>
          </ButtonsWrapperStyle>
          <SocialsStyle>
            <span>Share the ebook:</span>
            <FacebookShareButton url={url} quote={title}>
              <FacebookIcon height="16px" width="8px" color="#fff" />
            </FacebookShareButton>
            <LinkedinShareButton url={url} title={title} description={description}>
              <LinkedinIconStyle height="15px" width="17px" color="#fff" />
            </LinkedinShareButton>
            <TwitterShareButton url={url} via={url} title={title}>
              <TwitterIconStyle width="17px" height="15px" color="#fff" />
            </TwitterShareButton>
          </SocialsStyle>
          <PictureStyle>
            <source
              media="(max-width: 767px)"
              srcSet="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile-min.png, https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_mobile@2x.png 2x"
            />
            <source
              media="(min-width: 768px)"
              srcSet="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_cel.png, https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover_cel@2x.png 2x"
            />
            <ImgStyle
              src="https://lifeonscreen-storage.s3.eu-central-1.amazonaws.com/static/assets/LoS-book-cover.png"
              alt="Master Out-Of-Home advertising."
            />
          </PictureStyle>
        </ContentStyle>
      </GradientStyle>
    </ThemeProvider>
  )
}

export default ThankYou
